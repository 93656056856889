@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300&display=swap');

html, body {
  max-width: 100%;
  overflow-x: hidden;

  font-size: medium;
}
.container {
  max-width: 768px;
}
a {
  &:hover {
    cursor: pointer;
  }
}

.logo_box {
  margin-left: 40px;

  .logo {
    font-family: 'Manrope', sans-serif;
    color: white;
    font-weight: bold;
    background-color: green;
    padding: 0 4px;
    border-radius: 2px;

    .oi {
      padding-left: 7px;
      font-size: small;
      color: white;
    }
  }
}

header {
  padding: 20px 10px 10px 10px;

  .menu_button_box {
    display: inline-block;
    padding: 7px 13px;

    a, a:visited {
      color: black;
    }
    a:hover {
      cursor: pointer;
    }
  }
  .menu_button, .back_button, .search_button, .show_files_button {
    &:hover {
      cursor: pointer;
    }
  }

  .show_files_button {
    margin-right: 13px;
  }
  .right_icon {
    right: 10px;
    top: 10px;
    padding: 7px;
  }

  .page_title {
    display: inline-block;
    margin-left: 40px;
    color: green;
    font-size: smaller;

    span {
      margin-left: 4px;
      text-decoration: underline;
    }
  }
}

.left_menu {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background-color: rgb(253, 255, 253);
  text-align: left;
  // to see menu - comment line below
  transform: translate3d(-100vw, 0, 0);
  transition: transform .3s ease-in;

  .user_data {
    padding: 20px;

    span {
      margin-right: 20px;
    }
  }
  .patients_title {
    text-align: center;
    font-size: large;
    padding-top: 20px;
  }
  ul {
    list-style: none;

    li {
      width: 80%;
      padding: 10px 20px;
      border-radius: 4px;
      margin-bottom: 3px;
      //margin-top: 30px;
      background-color: rgb(240,250,250);

      a {
        display: inline-block;
        color: black;
        width:100%;
      }
    }
  }
  hr {
    width:70%;
  }
}
//.right_menu {
//  width: 50%;
//  position: absolute;
//  padding-bottom: 30px;
//  right: 0;
//  z-index: 1;
//  background-color: rgb(253, 255, 253);
//  border-bottom: 1px gray solid;
//  transform: translate3d(0, -100vw, 0);
//  transition: transform .3s ease-in;
//  font-weight: bold;
//
//  ul {
//    list-style: none;
//
//    li {
//      margin-top: 30px;
//      &:hover {
//        cursor: pointer;
//      }
//
//      a {
//        color: black;
//        &:hover {
//          text-decoration: none;
//        }
//      }
//    }
//  }
//}
.visible {
  transform: translate3d(0, 0vw, 0);
}


// landing page
.landing_page {
  text-align: center;

  h1 {
    padding-top: 50px;
    font-size: large;
  }
  .screenshots {
    padding-top: 20px;
    padding-bottom: 30px;

    video {
      border: 8px rgb(0,40,0) solid;
      border-radius: 10px;
    }
    img {
      max-width: 30%;
      padding: 8px;
    }
  }
  h2 {
    padding-top: 50px;
    padding-bottom: 10px;
    font-size: small;
  }
}
.lang_selector {
  border: 1px white solid;
}


// profile page
.profile_page {
  padding: 10px;
  white-space: pre-wrap;

  .profile_title {
    text-align: center;
    padding: 0 0 30px 0;
  }
  .edit_button {
    margin: 10px;
    font-size: x-small;
    color: darkblue;

    i {
      padding-right: 5px;
    }
  }
  .table {
    .title {
      font-size: smaller;
    }
  }
}


// settings page
.settings_page {
  padding: 10px;
  white-space: pre-wrap;

  .settings_title {
    text-align: center;
    padding: 0 0 30px 0;
    font-size: large;
  }
  table {
    .name {
      vertical-align: middle;
    }
  }
}


// history page
.history_page {
  padding: 10px;

  .history_title {
    text-align: center;
    padding: 0 0 30px 0;
  }
  a {
    color: black;

    &:hover {
      text-decoration: none;
    }
    //.history_title {
    //  text-align: center;
    //  padding: 15px;
    //  background-color: #b1dfbb;
    //  color: darkgreen;
    //  font-weight: bold;
    //}
    .card {
      padding: 10px 10px 30px 10px;
      margin-bottom: 45px;
      background-color: rgb(249, 253, 255);
      border: 2px rgb(220, 243, 245) solid;
      font-size: medium;

      .add_card {
        text-align: center;
        color: green;

        span {
          font-size: smaller;
          padding-right: 10px;
        }
      }
      .add_card_link {
        max-width: 200px;
      }
      .title_row {
        padding-bottom: 15px;

        .title {
          padding: 3px 3px 3px 10px;
          background-color: rgb(250, 250, 250);
          font-size: x-small;

          .card_id {
            span {
              display: inline-block;
              padding: 3px;
            }
          }
          .date {
            display: inline-block;
            padding: 5px;
            background-color: white;
          }
        }
        .type {
          text-align: right;

          .tag {
            display: block;
            padding: 3px;
            font-size: small;
            color: mediumblue;
          }
          .cardType {
            display: inline-block;
            padding: 10px 3px 5px 10px;
            font-size: small;
            color: #83a8d7;
          }
        }
      }
      .description {
        padding-bottom: 10px;
        white-space: pre-wrap;
      }
      .visited {
        padding-top: 6px;
        padding-left: 10px;
        font-size: small;

        .oi {
          margin-right: 10px;
          font-size: x-small;
        }
      }
      .files {
        margin-top: 15px;
        margin-left: 10px;
        font-size: small;

        img {
          width: 80px;
          margin-right: 10px;
          border: 1px rgb(220, 243, 245) solid;
          filter: opacity(50%);
        }
        div {
          margin-top: 10px;

          span {
            padding: 3px;
            background-color: #f3f0f3;
            border-radius: 3px;
          }
        }
        .oi {
          padding-right: 4px;
        }

        .text {
          margin-left: 10px;
        }
      }
      .enter_card_icon {
        padding: 0 10px 0 0;
        font-size: small;

        .oi {
          color: lightsteelblue;
        }
      }
    }
  }
}

// card page
.card_page {

  header {
    .float-right {
      .btn {
        margin-left: 20px;
      }
    }
  }
  .card_data {
    padding: 10px 0 40px 0;
    background-color: rgb(249, 253, 255);
    border: 1px rgb(220, 243, 245) solid;

    .card_data_box {
      padding: 0 20px 0 20px;
    }
    .title_elements {
      .type {
        font-size: small;
        text-align: right;

        .tag {
          display: block;
          padding-top: 5px;
          color: mediumblue;
        }
        .cardType {
          display: inline-block;
          padding: 5px 0 5px 10px;
          color: #6a9ad6;
        }
      }
      .date {
        color: gray;
        padding-bottom: 10px;
      }
    }
  }

  .paragraph {
    font-size: medium;
    padding-top: 40px;
    white-space: pre-wrap;

    .title {
      padding: 5px 10px 5px 30px;
      margin-bottom: 10px;
      font-size: x-small;
      font-style: italic;
      color: gray;
      //background: linear-gradient(to right, white, rgb(249, 253, 255));
      border-radius: 1px;

      .oi {
        margin-right: 10px;
        font-size: x-small;
      }
    }
    .files_title {
      padding-left: 50px;
    }
    ul {
      padding: 0 10px;
    }
    ul.pictures_list {
      list-style: none;
      padding: 0;

      img {
        width: 100%;
        margin-top: 10px;
        border: 1px lightgray solid;
        border-radius: 2px;
      }
      div {
        margin-top: 5px;
        padding: 10px 30px;
        background-color: #f3f0f3;
        border-radius: 5px;
      }
    }
  }
}


// show to doctor page
.show_to_doctor_page {
  h1 {
    font-size: large;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  div {
    padding: 10px;

    p {
      padding: 10px;

      .url {
        margin-left: 30px;
        font-weight: bold;
      }
      .btn {
        margin-top: 4px;
      }
    }
    .must {
      font-size: small;
    }
  }
  table {
    margin-bottom: 40px;

    .values {
      font-weight: bold;
    }
  }
}

// add card page
.add_card_page {
  .card_inputs {

    .add_files {
      font-size: small;

      .oi {
        margin-right: 8px;
        font-size: medium;
      }
    }
    .filepond_box {
      margin-top: 10px;
      margin-bottom: 20px;
      background-color: #f3f0f3;
      border-radius: 6px;

      p {
        font-size: small;
        padding: 12px 12px 0 12px;
      }
    }
    .ReactCollapse--collapse {
      transition: height 500ms;
    }
    .tags_box {
      button {
        margin-left: 7px;
      }
      .tags {
        display: block;
        padding: 10px 10px 0 10px;
      }
    }
    h2 {
      margin-left: 7px;

      .oi {
        padding-right: 7px;
      }
    }
    .not_required_fields {
      color: #c3a608;
      font-size: small;
    }
  }
}

// under construction page
.under_construction_page {

  .menu_button_box {
    .logo {
      margin-left: 37px;
    }
  }
  .content {
    margin-top: 80px;
    padding: 30px;

    h1 {
      font-size: medium;
      margin-top: 40px;
    }
  }
}

// login and register pages
.auth_pages {
  h1 {
    text-align: center;
    font-size: large;
    margin-top: 100px;
    margin-bottom: 30px;
  }
  .register_button_box {
    margin-top: 50px;
    font-size: small;
  }
}

.authenticate_patient_page {
  .title {
    text-align: center;
    font-size: medium;
    margin-top: 100px;
    padding-bottom: 30px;
  }
  button {
    margin-top: 50px;
  }
}