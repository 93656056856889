@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@300&display=swap);
html, body {
  max-width: 100%;
  overflow-x: hidden;
  font-size: medium; }

.container {
  max-width: 768px; }

a:hover {
  cursor: pointer; }

.logo_box {
  margin-left: 40px; }
  .logo_box .logo {
    font-family: 'Manrope', sans-serif;
    color: white;
    font-weight: bold;
    background-color: green;
    padding: 0 4px;
    border-radius: 2px; }
    .logo_box .logo .oi {
      padding-left: 7px;
      font-size: small;
      color: white; }

header {
  padding: 20px 10px 10px 10px; }
  header .menu_button_box {
    display: inline-block;
    padding: 7px 13px; }
    header .menu_button_box a, header .menu_button_box a:visited {
      color: black; }
    header .menu_button_box a:hover {
      cursor: pointer; }
  header .menu_button:hover, header .back_button:hover, header .search_button:hover, header .show_files_button:hover {
    cursor: pointer; }
  header .show_files_button {
    margin-right: 13px; }
  header .right_icon {
    right: 10px;
    top: 10px;
    padding: 7px; }
  header .page_title {
    display: inline-block;
    margin-left: 40px;
    color: green;
    font-size: smaller; }
    header .page_title span {
      margin-left: 4px;
      text-decoration: underline; }

.left_menu {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background-color: #fdfffd;
  text-align: left;
  transform: translate3d(-100vw, 0, 0);
  transition: transform .3s ease-in; }
  .left_menu .user_data {
    padding: 20px; }
    .left_menu .user_data span {
      margin-right: 20px; }
  .left_menu .patients_title {
    text-align: center;
    font-size: large;
    padding-top: 20px; }
  .left_menu ul {
    list-style: none; }
    .left_menu ul li {
      width: 80%;
      padding: 10px 20px;
      border-radius: 4px;
      margin-bottom: 3px;
      background-color: #f0fafa; }
      .left_menu ul li a {
        display: inline-block;
        color: black;
        width: 100%; }
  .left_menu hr {
    width: 70%; }

.visible {
  transform: translate3d(0, 0vw, 0); }

.landing_page {
  text-align: center; }
  .landing_page h1 {
    padding-top: 50px;
    font-size: large; }
  .landing_page .screenshots {
    padding-top: 20px;
    padding-bottom: 30px; }
    .landing_page .screenshots video {
      border: 8px #002800 solid;
      border-radius: 10px; }
    .landing_page .screenshots img {
      max-width: 30%;
      padding: 8px; }
  .landing_page h2 {
    padding-top: 50px;
    padding-bottom: 10px;
    font-size: small; }

.lang_selector {
  border: 1px white solid; }

.profile_page {
  padding: 10px;
  white-space: pre-wrap; }
  .profile_page .profile_title {
    text-align: center;
    padding: 0 0 30px 0; }
  .profile_page .edit_button {
    margin: 10px;
    font-size: x-small;
    color: darkblue; }
    .profile_page .edit_button i {
      padding-right: 5px; }
  .profile_page .table .title {
    font-size: smaller; }

.settings_page {
  padding: 10px;
  white-space: pre-wrap; }
  .settings_page .settings_title {
    text-align: center;
    padding: 0 0 30px 0;
    font-size: large; }
  .settings_page table .name {
    vertical-align: middle; }

.history_page {
  padding: 10px; }
  .history_page .history_title {
    text-align: center;
    padding: 0 0 30px 0; }
  .history_page a {
    color: black; }
    .history_page a:hover {
      text-decoration: none; }
    .history_page a .card {
      padding: 10px 10px 30px 10px;
      margin-bottom: 45px;
      background-color: #f9fdff;
      border: 2px #dcf3f5 solid;
      font-size: medium; }
      .history_page a .card .add_card {
        text-align: center;
        color: green; }
        .history_page a .card .add_card span {
          font-size: smaller;
          padding-right: 10px; }
      .history_page a .card .add_card_link {
        max-width: 200px; }
      .history_page a .card .title_row {
        padding-bottom: 15px; }
        .history_page a .card .title_row .title {
          padding: 3px 3px 3px 10px;
          background-color: #fafafa;
          font-size: x-small; }
          .history_page a .card .title_row .title .card_id span {
            display: inline-block;
            padding: 3px; }
          .history_page a .card .title_row .title .date {
            display: inline-block;
            padding: 5px;
            background-color: white; }
        .history_page a .card .title_row .type {
          text-align: right; }
          .history_page a .card .title_row .type .tag {
            display: block;
            padding: 3px;
            font-size: small;
            color: mediumblue; }
          .history_page a .card .title_row .type .cardType {
            display: inline-block;
            padding: 10px 3px 5px 10px;
            font-size: small;
            color: #83a8d7; }
      .history_page a .card .description {
        padding-bottom: 10px;
        white-space: pre-wrap; }
      .history_page a .card .visited {
        padding-top: 6px;
        padding-left: 10px;
        font-size: small; }
        .history_page a .card .visited .oi {
          margin-right: 10px;
          font-size: x-small; }
      .history_page a .card .files {
        margin-top: 15px;
        margin-left: 10px;
        font-size: small; }
        .history_page a .card .files img {
          width: 80px;
          margin-right: 10px;
          border: 1px #dcf3f5 solid;
          -webkit-filter: opacity(50%);
                  filter: opacity(50%); }
        .history_page a .card .files div {
          margin-top: 10px; }
          .history_page a .card .files div span {
            padding: 3px;
            background-color: #f3f0f3;
            border-radius: 3px; }
        .history_page a .card .files .oi {
          padding-right: 4px; }
        .history_page a .card .files .text {
          margin-left: 10px; }
      .history_page a .card .enter_card_icon {
        padding: 0 10px 0 0;
        font-size: small; }
        .history_page a .card .enter_card_icon .oi {
          color: lightsteelblue; }

.card_page header .float-right .btn {
  margin-left: 20px; }

.card_page .card_data {
  padding: 10px 0 40px 0;
  background-color: #f9fdff;
  border: 1px #dcf3f5 solid; }
  .card_page .card_data .card_data_box {
    padding: 0 20px 0 20px; }
  .card_page .card_data .title_elements .type {
    font-size: small;
    text-align: right; }
    .card_page .card_data .title_elements .type .tag {
      display: block;
      padding-top: 5px;
      color: mediumblue; }
    .card_page .card_data .title_elements .type .cardType {
      display: inline-block;
      padding: 5px 0 5px 10px;
      color: #6a9ad6; }
  .card_page .card_data .title_elements .date {
    color: gray;
    padding-bottom: 10px; }

.card_page .paragraph {
  font-size: medium;
  padding-top: 40px;
  white-space: pre-wrap; }
  .card_page .paragraph .title {
    padding: 5px 10px 5px 30px;
    margin-bottom: 10px;
    font-size: x-small;
    font-style: italic;
    color: gray;
    border-radius: 1px; }
    .card_page .paragraph .title .oi {
      margin-right: 10px;
      font-size: x-small; }
  .card_page .paragraph .files_title {
    padding-left: 50px; }
  .card_page .paragraph ul {
    padding: 0 10px; }
  .card_page .paragraph ul.pictures_list {
    list-style: none;
    padding: 0; }
    .card_page .paragraph ul.pictures_list img {
      width: 100%;
      margin-top: 10px;
      border: 1px lightgray solid;
      border-radius: 2px; }
    .card_page .paragraph ul.pictures_list div {
      margin-top: 5px;
      padding: 10px 30px;
      background-color: #f3f0f3;
      border-radius: 5px; }

.show_to_doctor_page h1 {
  font-size: large;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px; }

.show_to_doctor_page div {
  padding: 10px; }
  .show_to_doctor_page div p {
    padding: 10px; }
    .show_to_doctor_page div p .url {
      margin-left: 30px;
      font-weight: bold; }
    .show_to_doctor_page div p .btn {
      margin-top: 4px; }
  .show_to_doctor_page div .must {
    font-size: small; }

.show_to_doctor_page table {
  margin-bottom: 40px; }
  .show_to_doctor_page table .values {
    font-weight: bold; }

.add_card_page .card_inputs .add_files {
  font-size: small; }
  .add_card_page .card_inputs .add_files .oi {
    margin-right: 8px;
    font-size: medium; }

.add_card_page .card_inputs .filepond_box {
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: #f3f0f3;
  border-radius: 6px; }
  .add_card_page .card_inputs .filepond_box p {
    font-size: small;
    padding: 12px 12px 0 12px; }

.add_card_page .card_inputs .ReactCollapse--collapse {
  transition: height 500ms; }

.add_card_page .card_inputs .tags_box button {
  margin-left: 7px; }

.add_card_page .card_inputs .tags_box .tags {
  display: block;
  padding: 10px 10px 0 10px; }

.add_card_page .card_inputs h2 {
  margin-left: 7px; }
  .add_card_page .card_inputs h2 .oi {
    padding-right: 7px; }

.add_card_page .card_inputs .not_required_fields {
  color: #c3a608;
  font-size: small; }

.under_construction_page .menu_button_box .logo {
  margin-left: 37px; }

.under_construction_page .content {
  margin-top: 80px;
  padding: 30px; }
  .under_construction_page .content h1 {
    font-size: medium;
    margin-top: 40px; }

.auth_pages h1 {
  text-align: center;
  font-size: large;
  margin-top: 100px;
  margin-bottom: 30px; }

.auth_pages .register_button_box {
  margin-top: 50px;
  font-size: small; }

.authenticate_patient_page .title {
  text-align: center;
  font-size: medium;
  margin-top: 100px;
  padding-bottom: 30px; }

.authenticate_patient_page button {
  margin-top: 50px; }

